import React, { memo, useContext } from "react";
import { Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ActionPageIdx from "./actionComps/ActionPageIdx";

function PrivateRoute() {
  const { initialState } = useContext(AuthContext);

  const santa = initialState?.santa;

  const profileDraftDetails = santa?.profileDraftDetails;

  if (!santa) {
    // navigate to /login if santa is not found
    window.location.href = "/login";
    return;
  }

  if (santa?.accountStatus?.status === "blocked") {
    return <ActionPageIdx pageType="accountBlocked" />;
  }

  if (
    !initialState?.santa?.profileDraftDetails?.isOnboardingComplete &&
    !window.location.pathname.includes("/onboarding")
  ) {
    window.location.href = "/onboarding";
    return;
  }

  // if the sants is onboarded and he is on the onboarding page, navigate him to home page
  if (
    profileDraftDetails?.isOnboardingComplete &&
    window.location.pathname.includes("/onboarding") &&
    profileDraftDetails?.KYCStatus === "approved"
  ) {
    window.location.href = "/";
    return;
  }

  if (santa?.profileDraftDetails?.onboardingStatus === "inReview") {
    return <ActionPageIdx pageType="onboardingInReview" />;
  }
  if (santa?.profileDraftDetails?.onboardingStatus === "rejected") {
    return <ActionPageIdx pageType="onboardingRejected" />;
  }

  if (santa?.accountStatus?.status === "suspended") {
    return <ActionPageIdx pageType="suspended" />;
  }
  if (santa?.accountStatus?.status === "deactivated") {
    return <ActionPageIdx pageType="deactivated" />;
  }

  return <Outlet />;
}

export default memo(PrivateRoute);
