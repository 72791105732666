import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderForSantaApi } from "../../api/orderApi";
import Header2 from "../../assets/headers/Header2";
import usePullToRefresh, { RefreshLoader } from "../../hooks/usePullToRefresh";
import SlideToConfirmButton from "../../assets/buttons/SlideToConfirmButton";
import ContactSupportButton from "../../components/orders/liveOrderRequests/subC/ContactSupportButton";
import { FaAngleRight } from "react-icons/fa";
import OrderSummaryIdx from "../../components/orders/orderDetails/liveOrder/subC/orderSummary/OrderSummaryIdx";
import TeamRow from "../../components/orders/liveOrderRequests/subC/TeamRow";
import moment from "moment";
import { santaApi } from "../../api/santaApi";
import WorkInfoContainer from "../../components/orders/liveOrderRequests/subC/WorkInfoContainer";
import EarningSummary from "../../components/orders/orderDetails/subC/earningSummary/EarningSummary";
import OrderAcceptedPage from "../../components/orders/liveOrderRequests/subC/OrderAcceptedPage";
import ServiceEarningQuickView from "../../components/orders/liveOrderRequests/subC/ServiceEarningQuickView";
import useFetchInitialData from "../../hooks/useFetchInitialData";
import { useAuthContext } from "../../context/AuthContext";
import OrderPassedPage from "../../components/orders/liveOrderRequests/subC/OrderPassedPage";
import { handleOrderAccept } from "../../components/orders/liveOrderRequests/subC/helperFun";
import { GiSandsOfTime } from "react-icons/gi";

export const projectionString =
  "date _id santaGroups productId images addressId materialsReturningStatus imagesInstructionForSanta santaInstructions priceDetail orderWallet santaGroups rawMaterials tags addOns freebie bookingStatus  orderServiceStatus orderOtp";

export default function OrderRequestDetailsPage() {
  const { orderId } = useParams();

  // importing the fetch initial data hook from hooks folder
  const { refetchSantaDetails } = useFetchInitialData();

  const nav = useNavigate();
  const containerRef = useRef(null);
  const {
    initialState: { santaId, santa },
  } = useAuthContext();

  const [refereshStates, setRefereshStates] = useState({});

  const [states, setStates] = useState({
    isLoading: true,
    order: null,
    orderSummaryPage: false,
    isMissedOrder: false,
    isPassingTheOrder: false,
    isOrderAcceptedPage: false,
    isOrderPassedPage: false,
  });

  usePullToRefresh(
    containerRef,
    () => onInitialLoad(),
    (params) => {
      setRefereshStates(params);
    },
    {
      isGradient: false,
      loaderColor: "#2136d4",
    }
  );

  useEffect(() => {
    onInitialLoad();

    // eslint-disable-next-line
  }, [orderId]);

  const onInitialLoad = async () => {
    setStates((p) => ({ ...p, isLoading: true }));
    try {
      // check whether the order is in the liveRequestArray of the santa or not
      // if not then navigate to the orders page
      if (!santa?.liveOrderRequests?.find((o) => o.order._id === orderId)) {
        nav("/orders");
        return;
      }

      // handle order fetch
      const res = await getOrderForSantaApi({
        orderId,
        projectionString: projectionString,
      });

      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          order: res.order,
          isMissedOrder:
            moment(res.order?.santaGroups?.requestSentOn)
              .add(res?.order?.santaGroups?.timeForRequestResponse, "seconds")
              .diff(moment(), "seconds") < 1,
        }));
      }
    } catch (error) {
      console.log(error);
    }
    setStates((p) => ({ ...p, isLoading: false }));
  };

  const handleOrderPass = async () => {
    setStates((p) => ({ ...p, isPassingTheOrder: true }));
    try {
      const res = await santaApi({
        tags: ["order", "liveOrderRequestHandler"],
        orderId: states.order._id,
        santaId,
        action: "pass",
      });

      if (res.isSuccess) {
        await refetchSantaDetails();

        // present the order passed page
        setStates((p) => ({ ...p, isOrderPassedPage: true }));
      } else {
        window.toast("Oops! something went wrong", "error");
      }
    } catch (error) {
      console.error(error);
    }

    setStates((p) => ({ ...p, isPassingTheOrder: false }));
  };

  if (states.isLoading) {
    return (
      <div className="fix-screen flex-center gap-2 ">
        <Header2 navFun={() => nav("/orders")} title={"Back to orders"} />

        <div className="loader w-[2rem]" />
        <p>Loading order...</p>
      </div>
    );
  }

  if (states.isOrderAcceptedPage) {
    return <OrderAcceptedPage order={states.order} />;
  }

  if (states.isOrderPassedPage) {
    return <OrderPassedPage />;
  }

  return (
    <>
      <Header2
        navFun={() => nav("/orders")}
        title={`#${states.order._id}`}
        order={states.order}
      />

      <OrderSummaryIdx
        order={states.order}
        states={states}
        setStates={setStates}
        isAcceptedOrder={false}
      />

      <div className="fix-screen ">
        <div
          ref={containerRef}
          className="text-[.875rem] pt-[4rem] space-y-4 items-center scroll-bar-remove overflow-x-hidden overflow-y-scroll h-full pb-[12rem] bg-blue-50 flex flex-col"
        >
          <RefreshLoader refereshStates={refereshStates} />
          <ServiceEarningQuickView order={states.order} />
          <WorkInfoContainer order={states.order} />

          {/* decor details container */}
          <div className="w-full px-4">
            <div
              onClick={() =>
                setStates((p) => ({ ...p, orderSummaryPage: true }))
              }
              className="px-4 w-full flex-col items-start bg-[white] border border-gray-200 rounded-lg py-4 gap-2"
            >
              <div className=" items-center w-full flex gap-2">
                <img
                  className="  size-[3.5rem] border bg-blue-100  rounded-lg aspect-square"
                  src={states.order?.productId?.images[0]}
                  alt=""
                />
                <div className="flex flex-col w-full">
                  <div className="text-sm font-[500] flex items-center gap-1 ">
                    View decor
                  </div>
                  <div className="text-xs font-[400] text-[gray] leading-4">
                    View booked decoration details and materials required
                  </div>
                </div>
                <FaAngleRight className="text-[1.25rem]" />
              </div>
              {states.order.addOns.length > 0 && (
                <div className="w-full flex items-center mt-2 ">
                  {states.order.addOns.slice(0, 6).map((addOn, idx) => {
                    return (
                      <img
                        className="w-[2rem] -mr-4 rounded-full border border-blue-200"
                        alt=""
                        src={addOn.addonId?.addonImage}
                        key={idx}
                      />
                    );
                  })}
                  <div className="font-medium ml-6">
                    {states.order.addOns.length + " Add-ons"}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full px-4">
            <p className="text-[1rem] text-[gray] mb-1 px-1 font-[500] ">
              Earning
            </p>
            <EarningSummary
              adjustmentsArr={states?.order?.orderWallet || []}
              santaHandlingFee={states.order?.santaGroups?.santaHandlingFee}
              santaServiceCosting={
                states.order?.santaGroups?.santaServiceCosting
              }
              santaTip={states.order?.priceDetail?.santaTip}
              order={states.order}
            />
          </div>
          <TeamRow order={states.order} />
          <ContactSupportButton />
        </div>
      </div>

      <TimeOfResponseRemaining
        {...{
          states,
          setStates,
          order: states.order,
          timeForRequestResponse:
            states?.order.santaGroups.timeForRequestResponse,
          requestSentOn: states?.order.santaGroups.requestSentOn,
        }}
      />

      <div
        className={`fixed bottom-0 right-0 p-3 left-0 h-[7.5rem] bg-white  `}
      >
        <div
          className={` w-full  ${
            states.isMissedOrder && "opacity-[0.7] pointer-events-none "
          } `}
        >
          <SlideToConfirmButton
            btnText={"Accept"}
            funOnFullSlide={() => {
              handleOrderAccept({
                santa,
                states,
                setStates,
                order: states.order,
                refetchSantaDetails,
              });
            }}
            disabled={states.isPassingTheOrder}
          />
          <div
            onClick={() => {
              if (!states.isPassingTheOrder) {
                window.warningModal({
                  msg: "Want to pass the order?",
                  lb_text: "Pass",
                  lb_fn: handleOrderPass,
                  rb_text: "No",
                });
              }
            }}
            className="w-full flex-center gap-2 text-base mt-3 font-medium text-red-600"
          >
            {states.isPassingTheOrder ? (
              <>
                <div
                  className="loader w-[1rem]"
                  style={{
                    borderTopColor: "red",
                  }}
                />
                Passing...
              </>
            ) : (
              "Pass"
            )}
          </div>{" "}
        </div>
      </div>
    </>
  );
}
const TimeOfResponseRemaining = ({
  timeForRequestResponse,
  requestSentOn,
  setStates,
  states,
}) => {
  const [counter, setCounter] = useState(
    moment(requestSentOn)
      .add(timeForRequestResponse, "seconds")
      .diff(moment(), "seconds")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (counter < 1) {
      setStates((p) => ({ ...p, isMissedOrder: true }));
    }

    // eslint-disable-next-line
  }, [counter]);

  const hours = Math.floor(Math.floor(counter / 60) / 60);

  const minutes = Math.floor(counter / 60) - hours * 60;

  const seconds = counter % 60;

  if (states.isMissedOrder) {
    return (
      <div className="fixed right-0 left-0 bottom-[8rem] flex-center ">
        <div className=" flex  border-blue-300 blue-gradient items-center font-[600] rounded-lg px-4 py-3 gap-1 justify-center">
          <GiSandsOfTime className=" text-xl " />
          <div className="text-sm font-500 ">You missed the order</div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed right-0 left-0 bottom-[8rem] flex-center ">
      <div className="flex items-center blue-gradient px-5 py-2 pb-3 rounded-lg ">
        <div className="text-base font-medium mr-3 ">Accept in: </div>
        <div className="flex items-center flex-wrap gap-x-1 whitespace-nowrap">
          {hours > 0 && (
            <>
              <div className="flex-col items-center">
                <div className="text-[1.125rem]  font-bold -mb-1">{hours}</div>
                <div className="text-[10px] leading-3 ">Hours</div>
              </div>
              <div className="text-[1.125rem] font-bold mx-1">:</div>
            </>
          )}

          <div className="flex-col items-center">
            <div className="text-[1.125rem] font-bold -mb-1">{minutes}</div>
            <div className="text-[10px] leading-3 ">min</div>
          </div>
          <div className="text-[1.125rem] font-bold mx-1">:</div>
          <div className="flex-col items-center">
            <div className="text-[1.125rem]  font-bold -mb-1">{seconds}</div>
            <div className="text-[10px] leading-3 ">sec</div>
          </div>
        </div>
      </div>
    </div>
  );
};
