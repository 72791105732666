import { MdPauseCircleFilled } from "react-icons/md"; // An icon to indicate suspension

export default function AccountSuspendedPage({ santa }) {
  const accountStatus = santa?.accountStatus; // accountStatus:{status: 'suspended', remark: "reason"}

  return (
    <div className="px-2">
      <div className="flex flex-col justify-center items-center mt-4 bg-white w-full p-6 rounded-[.5rem] shadow-lg">
        <MdPauseCircleFilled className="text-yellow-500 text-[4rem] mb-4" />
        <p className="mb-2 text-[1.5rem] font-bold text-gray-800">
          ACCOUNT SUSPENDED
        </p>
        <p className="text-gray-600">Your account is currently suspended.</p>
      </div>

      <div className="mt-8 bg-gray-50 border w-full p-4 rounded-[.5rem] shadow">
        <p className="text-lg font-semibold mb-2 text-gray-800">Reason</p>
        <p className="italic text-gray-600 mb-4">
          {accountStatus?.remark || "Not specified"}
        </p>

        <p className="text-md font-semibold mb-2 text-gray-800">
          Recommended Actions:
        </p>
        <div className="space-y-2 text-gray-600">
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Contact support to discuss reinstatement.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Review community guidelines to ensure compliance.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Monitor your email or messages for further updates.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
