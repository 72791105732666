import { MdBlock } from "react-icons/md";

export default function AccountBlockedPage({ santa }) {
  const accountStatus = santa?.accountStatus; // accountStatus:{status: 'blocked', remark: "reason"}

  return (
    <div className="px-2">
      <div className="flex flex-col justify-center items-center mt-4 bg-white w-full p-6 rounded-[.5rem] shadow-lg">
        <MdBlock className="text-red-500 text-[4rem] mb-4" />
        <p className="mb-2 text-[1.5rem] font-bold text-gray-800">
          ACCESS DENIED
        </p>
        <p className="text-gray-600">Your account has been blocked.</p>
      </div>

      <div className="mt-8 bg-gray-50 border w-full p-4 rounded-[.5rem] shadow">
        <p className="text-lg font-semibold mb-2 text-gray-800">Reason</p>
        <p className="italic text-gray-600 mb-4">
          {accountStatus?.remark || "Not specified"}
        </p>

        <p className="text-md font-semibold mb-2 text-gray-800">Next Steps:</p>
        <div className="space-y-2 text-gray-600">
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Contact support to resolve the issue.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>Review the terms of service to avoid future issues.</p>
          </div>
          <div className="flex items-start">
            <span className="mr-2">•</span>
            <p>
              Check your WhatsApp/email for further instructions from support.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
