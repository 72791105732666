import React, { createContext, useState, useContext } from "react";
import { base64ToFile } from "../utils/helperFn";

const CompRenderingContext = createContext();

// creating a hook
export const useCompRendering = () => useContext(CompRenderingContext);

export const CompRenderingProvider = ({ children }) => {
  const [onboardingSuccessModal, setOnboardingSuccessModal] = useState({
    isRender: false,
    type: "",
  });

  const [successMsg, setSuccessMsg] = useState({
    msg: "",
    isRender: false,
  });

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    isRender: false,
  });

  const [states, setStates] = useState({
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
    toast1: {
      isRender: false,
      text: "",
      options: {
        customZIndex: 1000,
      },
    },
    DateRangeSelector1: {
      isRender: false,
      type: "",
    },
    FloatingCustomModal: {
      isRender: false,
      component: null,
      UIOptions: {},
      options: {},
    },
    askForInputs: {
      isRender: false,
      labels: [],
      heading: "",
      subHeading: "",
      onSubmitCallback: null,
      saveBtnTxt: "Done",
    },
    askForRadio: {
      isRender: false,
      labels: [],
      heading: "",
      subHeading: "",
      onSubmitCallback: null,
      saveBtnTxt: "Done",
      isOtherOptionAvailable: false,
    },
    LoadingScreen1: {
      isRender: false,
      msg: "",
    },
    AskImagePickFrom: {
      isRender: false,
      msg: "",
      imagePickInitiated: false,
      result: null,
    },
    addBankDetailsModal: false,
    CallToUserModal: {
      isRender: false,
      user: [],
      onErrorCallBack: () => {},
    },
  });

  window.toast = (msg, type) => {
    setStates((p) => ({
      ...p,
      toast1: {
        isRender: true,
        text: msg,
        options: {
          customZIndex: 15,
          type: type || "",
        },
      },
    }));
  };

  window.floatingModal = (isRender, component, UIOptions, options) => {
    // options : { onHandleClose: () => {} }
    setStates((p) => ({
      ...p,
      FloatingCustomModal: {
        isRender,
        component,
        UIOptions,
        options,
      },
    }));
  };

  window.loadingScreen = (isLoading, msg) => {
    setStates((p) => ({
      ...p,
      LoadingScreen1: {
        isRender: isLoading,
        msg: msg || "",
      },
    }));
  };

  window.ask = (heading, subHeading, labels, saveBtnTxt, onSubmitCallback) => {
    setStates((p) => ({
      ...p,
      askForInputs: {
        isRender: true,
        labels,
        heading,
        subHeading,
        onSubmitCallback,
        saveBtnTxt,
      },
    }));
  };

  window.askRadio = (
    heading,
    subHeading,
    labels,
    saveBtnTxt,
    onSubmitCallback,
    isOtherOptionAvailable
  ) => {
    // labels are an array of strings  will be shown in radio buttons
    // onSubmitCallback is a function which will be called on submit with chosen value
    setStates((p) => ({
      ...p,
      askForRadio: {
        isRender: true,
        labels,
        heading,
        subHeading,
        onSubmitCallback,
        saveBtnTxt,
        isOtherOptionAvailable,
      },
    }));
  };

  window.warningModal = ({ msg, rb_text, lb_text, rb_fn, lb_fn }) => {
    setStates((p) => ({
      ...p,
      warningModel: {
        msg,
        leftBtnText: lb_text,
        rightBtnText: rb_text,
        funOnLeftBtn: lb_fn,
        funOnRightBtn: rb_fn,
        isRender: true,
      },
    }));
  };

  window.postMessageRW = (props) => {
    const message = {
      ...props,
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  };

  window.pickImage = ({
    pickFrom = "choice", // choice , camera, gallery
    cameraType = "front", // front, back
    heading = "Pick image",
    allowsEditing = false,
    isRender = true,
    onImagePickEnd = (file, base64URL, readedFile) => {},
  }) => {
    setStates((p) => ({
      ...p,
      AskImagePickFrom: {
        isRender: isRender,
        onImagePickEnd: onImagePickEnd,
        pickFrom,
        allowsEditing,
        heading,
        cameraType,
      },
    }));
  };

  window.listenToImagePicker = async (data) => {
    try {
      const jsonData = JSON.parse(JSON.stringify(data));

      const base64Url = jsonData?.base64;
      const isCancelled = jsonData.canceled;
      const imagePickFromData = JSON.parse(jsonData?.data);
      if (isCancelled) {
        states?.AskImagePickFrom?.onImagePicked({
          isCancelled,
        });
        return;
      }

      const file = base64ToFile(base64Url, "pickedImage.jpg", "image/jpeg");

      states?.AskImagePickFrom?.onImagePicked({
        file,
        imagePickFromData,
        base64Url,
        isCancelled,
      });
    } catch (error) {
      window.toast(error.message, "error");
    }
  };

  return (
    <CompRenderingContext.Provider
      value={{
        onboardingSuccessModal,
        setOnboardingSuccessModal,
        successMsg,
        setSuccessMsg,
        toastMsg,
        setToastMsg,
        states,
        setStates,
      }}
    >
      {children}
    </CompRenderingContext.Provider>
  );
};

export default CompRenderingContext;
